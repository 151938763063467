import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import { colors } from "@mui/material";
import Container from "../Container";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { Fade } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const serviceCards = [
  {
    color: colors.blue[500],
    title: "AI Solutions & Integration",
    subtitle: "Custom AI systems to optimize workflows and operations with seamless integration.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
    path: "/automated-ai-solutions",
  },
  {
    color: colors.indigo[500],
    title: "Private AI & Security",
    subtitle: "Deploy secure, private LLMs with full data protection and compliance.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        />
      </svg>
    ),
    path: "/private-deployment",
  },
  {
    color: colors.green[500],
    title: "End-to-End AI Development",
    subtitle: "Comprehensive AI product creation from concept to deployment and scaling.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
        />
      </svg>
    ),
    path: "/product-development",
  },
  {
    color: colors.amber[500],
    title: "AI Strategy & Consulting",
    subtitle: "Expert guidance to navigate AI opportunities and strategic implementation.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
    path: "/strategy-consulting",
  }
];

const FeatureCardsWithColorfullIconsAndLearnMoreLink = () => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );
    
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);
  
  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        py: { xs: 4, md: 8 },
      }}
    >
      <Container>
        <Box 
          ref={ref}
          sx={{ 
            textAlign: "center",
            mb: { xs: 4, sm: 6 },
            maxWidth: 800,
            mx: 'auto'
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: 700,
              mb: 2,
              color: theme.palette.text.primary,
            }}
          >
            Our Services
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              color: theme.palette.text.secondary,
              mb: 2,
              lineHeight: 1.6,
            }}
          >
            We provide a comprehensive range of AI services to help your organization
            leverage cutting-edge technology for maximum impact.
          </Typography>
        </Box>
        
        <Grid container spacing={4}>
          {serviceCards.map((item, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              key={i}
            >
              <Fade
                in={isVisible}
                timeout={700}
                style={{
                  transitionDelay: `${i * 150}ms`,
                }}
              >
                <Card
                  component={Link}
                  to={item.path}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                    p: 3,
                    textDecoration: 'none',
                    boxShadow: theme.palette.mode === 'dark' 
                      ? '0 6px 20px rgba(0,0,0,0.2)' 
                      : '0 5px 15px rgba(0,0,0,0.05)',
                    '&:hover': {
                    
                      boxShadow: theme.palette.mode === 'dark'
                        ? '0 15px 30px rgba(0,0,0,0.4)'
                        : '0 15px 35px rgba(0,0,0,0.1)',
                      '& .MuiAvatar-root': {
                        backgroundColor: item.color,
                        '& svg': {
                          color: 'white',
                          transform: 'scale(1.1)',
                        },
                      },
                    },
                  }}
                  elevation={0}
                >
                  <CardContent sx={{ p: 2, flexGrow: 1 }}>
                    <Avatar
                      sx={{
                        mb: 3,
                        bgcolor: alpha(item.color, 0.12),
                        color: item.color,
                        width: 64,
                        height: 64,
                        boxShadow: `0 5px 15px 0 ${alpha(item.color, 0.25)}`,
                        transition: 'all 0.3s ease',
                        '& svg': {
                          transition: 'transform 0.3s ease',
                        }
                      }}
                    >
                      {item.icon}
                    </Avatar>
                    <Typography
                      variant="h5"
                      gutterBottom
                      component="h3"
                      sx={{
                        fontWeight: 700,
                        color: theme.palette.text.primary,
                        mb: 2,
                        fontSize: { xs: '1.25rem', md: '1.5rem' },
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ 
                        color: theme.palette.text.secondary,
                        lineHeight: 1.7,
                        mb: 3,
                      }}
                    >
                      {item.subtitle}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 'auto',
                        color: item.color,
                        fontWeight: 600,
                        fontSize: '0.875rem',
                        '&:hover': { textDecoration: 'underline' },
                      }}
                    >
                      Learn More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        style={{ marginLeft: '6px' }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        />
                      </svg>
                    </Box>
                  </CardContent>
                </Card>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeatureCardsWithColorfullIconsAndLearnMoreLink;