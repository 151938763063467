import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link, Stack, Divider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  const theme = useTheme();
  
  const footerLinks = [
    {
      title: 'Company',
      links: [
        { text: 'About Us', url: '/#team' },
        { text: 'Projects', url: '/projects' },
        { text: 'Contact', url: '/contact' },
      ]
    },
    {
      title: 'Services',
      links: [
        { text: 'AI Solutions', url: '/automated-ai-solutions' },
        { text: 'LLM Deployment', url: '/private-deployment' },
        { text: 'Consulting', url: '/strategy-consulting' },
      ]
    },
    {
      title: 'Legal',
      links: [
        { text: 'Privacy Policy', url: '#' },
        { text: 'Terms of Service', url: '#' },
        { text: 'Cookie Policy', url: '#' },
      ]
    }
  ];

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box mb={2}>
            <Typography
              variant="h5"
              component="a"
              href="/"
              sx={{
                fontWeight: 800,
                color: theme.palette.primary.main,
                textDecoration: 'none',
                fontFamily: "'Inter', sans-serif",
                lineHeight: 1.5,
                mb: 2,
                display: 'inline-block',
              }}
            >
              Pillar1
            </Typography>
            <Typography 
              variant="body2" 
              color="textSecondary"
              sx={{ mb: 2, maxWidth: 280 }}
            >
              AI solution implementation consulting and product development services empowering businesses.
            </Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              aria-label="twitter"
              sx={{ 
                color: theme.palette.text.secondary,
                '&:hover': {
                  color: '#1DA1F2',
                  backgroundColor: 'rgba(29, 161, 242, 0.1)',
                }
              }}
            >
              <TwitterIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="github"
              sx={{ 
                color: theme.palette.text.secondary,
                '&:hover': {
                  color: '#333',
                  backgroundColor: 'rgba(51, 51, 51, 0.1)',
                }
              }}
            >
              <GitHubIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="linkedin"
              sx={{ 
                color: theme.palette.text.secondary,
                '&:hover': {
                  color: '#0077B5',
                  backgroundColor: 'rgba(0, 119, 181, 0.1)',
                }
              }}
            >
              <LinkedInIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Grid>
        
        {footerLinks.map((section, i) => (
          <Grid item xs={12} sm={6} md={2} key={i}>
            <Typography
              variant="subtitle2"
              sx={{ 
                fontWeight: 700, 
                mb: 2,
                color: theme.palette.text.primary
              }}
            >
              {section.title}
            </Typography>
            <Stack spacing={1}>
              {section.links.map((link, j) => (
                <Link
                  key={j}
                  component={RouterLink}
                  to={link.url}
                  underline="hover"
                  variant="body2"
                  sx={{ 
                    color: theme.palette.text.secondary,
                    '&:hover': { color: theme.palette.primary.main },
                    display: 'block',
                    py: 0.5,
                  }}
                >
                  {link.text}
                </Link>
              ))}
            </Stack>
          </Grid>
        ))}
      </Grid>
      
      <Divider sx={{ my: 4 }} />
      
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' }, 
          justifyContent: 'space-between',
          alignItems: { xs: 'center', sm: 'flex-start' },
          gap: 2
        }}
      >
        <Typography
          variant="caption"
          sx={{ 
            color: theme.palette.text.secondary,
            textAlign: { xs: 'center', sm: 'left' }
          }}
        >
          &copy; {new Date().getFullYear()} Pillar1. All rights reserved.
        </Typography>
        <Typography
          variant="caption"
          sx={{ 
            color: theme.palette.text.secondary,
            textAlign: { xs: 'center', sm: 'right' },
            maxWidth: { xs: '100%', sm: '60%' }
          }}
        >
          When you visit or interact with our site, we may use cookies to improve your experience.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;