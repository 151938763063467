import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import { light, dark } from "./palette";

const getTheme = (mode, themeToggler) =>
	responsiveFontSizes(
		createTheme({
			palette: mode === "light" ? light : dark,
			shadows: shadows(mode),
			typography: {
				fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
				h1: {
					fontWeight: 700,
				},
				h2: {
					fontWeight: 700,
				},
				h3: {
					fontWeight: 600,
				},
				h4: {
					fontWeight: 600,
				},
				h5: {
					fontWeight: 500,
				},
				h6: {
					fontWeight: 500,
				},
				button: {
					textTransform: "none",
					fontWeight: 500,
				},
				subtitle1: {
					fontWeight: 400,
				},
				subtitle2: {
					fontWeight: 400,
				},
			},
			zIndex: {
				appBar: 1200,
				drawer: 1300,
			},
			components: {
				MuiButton: {
					styleOverrides: {
						root: {
							fontWeight: 500,
							borderRadius: 8,
							padding: "10px 20px",
							boxShadow: "none",
							"&:hover": {
								boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
							},
						},
						containedPrimary: {
							"&:hover": {
								backgroundColor:
									mode === "light" ? light.primary.dark : dark.primary.dark,
							},
						},
						containedSecondary: {
							color: "white",
							"&:hover": {
								backgroundColor:
									mode === "light" ? light.secondary.dark : dark.secondary.dark,
							},
						},
						outlined: {
							borderWidth: 2,
							"&:hover": {
								borderWidth: 2,
							},
						},
					},
				},
				MuiInputBase: {
					styleOverrides: {
						root: {
							borderRadius: 8,
						},
					},
				},
				MuiOutlinedInput: {
					styleOverrides: {
						root: {
							borderRadius: 8,
						},
						input: {
							padding: "14px 16px",
						},
					},
				},
				MuiCard: {
					styleOverrides: {
						root: {
							borderRadius: 12,
							boxShadow:
								mode === "light"
									? "0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)"
									: "0px 4px 8px rgba(0, 0, 0, 0.2)",
							transition:
								"transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
							"&:hover": {
								boxShadow:
									mode === "light"
										? "0px 8px 16px rgba(0, 0, 0, 0.1)"
										: "0px 8px 24px rgba(0, 0, 0, 0.3)",
							},
						},
					},
				},
				MuiContainer: {
					styleOverrides: {
						root: {
							paddingLeft: 24,
							paddingRight: 24,
							[createTheme().breakpoints.up("sm")]: {
								paddingLeft: 40,
								paddingRight: 40,
							},
						},
					},
				},
				MuiAppBar: {
					styleOverrides: {
						root: {
							boxShadow:
								mode === "light"
									? "0px 1px 3px rgba(0, 0, 0, 0.08)"
									: "0px 1px 8px rgba(0, 0, 0, 0.2)",
						},
					},
				},
				MuiLink: {
					styleOverrides: {
						root: {
							textDecoration: "none",
							"&:hover": {
								textDecoration: "none",
							},
						},
					},
				},
			},
			themeToggler,
		})
	);

export default getTheme;
