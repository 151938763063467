import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { primaryBlue, lightGradient, darkGradient, bodyColor } from "theme/constants";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { alpha, ListItemButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTheme } from "@mui/material/styles";

const pages = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Services",
    link: "/services",
    isMenu: true,
    menuOptions: [
      { option: "Automated AI Solutions", link: "/automated-ai-solutions" },
      { option: "Private LLM Deployment", link: "/private-deployment" },
      { option: "AI Consulting Strategies", link: "/strategy-consulting" },
      {
        option: "End-to-End AI Architecture",
        link: "/end-to-end-architecture",
      },
      { option: "Collaboration with teams", link: "/collaboration" },
      { option: "AI Product Development", link: "/product-development" },
      { option: "Data Analysis & Insights", link: "/data-analysis" },
      { option: "Experienced AI Engineers", link: "/AiEngineers" },
    ],
  },
  {
    title: "Projects",
    link: "/projects",
  },
  {
    title: "Meet the team",
    link: "/#team",
  },
  {
    title: "Contact",
    link: "/contact",
    cta: true,
  },
];

function ResponsiveAppBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);

  const handleMenuOpen = (event, menuOptions) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menuOptions);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const handleMenuClick = (link) => {
    navigate(link);
    handleMenuClose();
  };
  const theme = useTheme();
  
  return (
    <AppBar
      position="sticky"
      sx={{
        top: 0,
        background: theme.palette.background.default,
        zIndex: 1040,
        py: { xs: 1, md: 1.5 },
        boxShadow: trigger ? 1 : 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
      elevation={trigger ? 1 : 0}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#appBar"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "'Inter', sans-serif",
              fontWeight: 800,
              letterSpacing: ".1rem",
              color: theme.palette.primary.main,
              textDecoration: "none",
            }}
            onClick={() => (window.location = "/")}
          >
            Pillar1
          </Typography>
          
          {/* MOBILE NAV BAR MENU */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="open drawer"
              aria-controls="drawer-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
              sx={{ color: theme.palette.text.primary }}
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{
                  width: 280,
                  height: "100%",
                  background: theme.palette.background.default,
                  pt: 2,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, mb: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ 
                      fontWeight: 700, 
                      color: theme.palette.primary.main,
                      fontFamily: "'Inter', sans-serif",
                    }}
                  >
                    Pillar1
                  </Typography>
                  <IconButton
                    sx={{
                      color: theme.palette.text.primary,
                    }}
                    onClick={toggleDrawer(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <List>
                  {pages.map((item, i) =>
                    item.isMenu ? (
                      <Box
                        key={i}
                        sx={{ px: 2, mb: 2 }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ 
                            color: theme.palette.text.primary, 
                            fontWeight: 600, 
                            py: 1 
                          }}
                        >
                          {item.title}
                        </Typography>
                        {item.menuOptions.map((menuItem, j) => (
                          <ListItemButton
                            key={j}
                            onClick={() => handleMenuClick(menuItem.link)}
                            sx={{ 
                              color: theme.palette.text.secondary,
                              py: 0.75,
                              pl: 2,
                              borderRadius: 1,
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                              }
                            }}
                          >
                            <ListItemText 
                              primary={menuItem.option} 
                              primaryTypographyProps={{ 
                                fontSize: '0.9rem',
                                fontWeight: 400,
                              }}
                            />
                          </ListItemButton>
                        ))}
                      </Box>
                    ) : (
                      <Link
                        key={i}
                        to={item.link}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.primary,
                        }}
                      >
                        <ListItemButton 
                          sx={{ 
                            color: theme.palette.text.primary,
                            px: 3,
                            py: 1.5,
                            '&:hover': {
                              backgroundColor: alpha(theme.palette.primary.main, 0.08),
                            },
                            ...(item.cta && {
                              mx: 2,
                              mt: 1,
                              backgroundColor: theme.palette.primary.main,
                              color: '#fff',
                              borderRadius: 1,
                              '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                              }
                            })
                          }}
                        >
                          <ListItemText 
                            primary={item.title} 
                            primaryTypographyProps={{ 
                              fontWeight: item.cta ? 600 : 500,
                            }}
                          />
                        </ListItemButton>
                      </Link>
                    )
                  )}
                </List>
              </Box>
            </Drawer>
          </Box>

          {/* Mobile Logo */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#appBar"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "'Inter', sans-serif",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: theme.palette.primary.main,
              textDecoration: "none",
            }}
            onClick={() => (window.location = "/")}
          >
            Pillar1
          </Typography>
          
          {/* Desktop Navigation */}
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                md: "flex",
                justifyContent: "flex-end",
              },
            }}
          >
            {pages.map((item, i) =>
              item.isMenu ? (
                <Box
                  key={i}
                  sx={{ mr: 1 }}
                >
                  <Button
                    sx={{
                      color: theme.palette.text.primary,
                      mx: 0.5,
                      px: 2,
                      py: 1,
                      borderRadius: 1,
                      backgroundColor: currentMenu
                        ? alpha(theme.palette.primary.main, 0.08)
                        : "transparent",
                      "&:hover": { 
                        backgroundColor: alpha(theme.palette.primary.main, 0.08) 
                      },
                    }}
                    onClick={(e) => handleMenuOpen(e, item.menuOptions)}
                    endIcon={
                      currentMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    }
                  >
                    {item.title}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorEl && currentMenu === item.menuOptions)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{ 
                      mt: 1,
                      '& .MuiPaper-root': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
                        borderRadius: 2,
                      } 
                    }}
                    PaperProps={{
                      elevation: 3,
                    }}
                  >
                    {item.menuOptions.map((menuItem, j) => (
                      <MenuItem
                        key={j}
                        onClick={() => handleMenuClick(menuItem.link)}
                        sx={{
                          "&:hover": {
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          },
                          py: 1.5,
                          px: 3,
                          color: theme.palette.text.secondary,
                          minWidth: 200,
                          mx: 0.5,
                          borderRadius: 1,
                        }}
                      >
                        {menuItem.option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                <Link
                  key={i}
                  to={item.link}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Button
                    variant={item.cta ? "contained" : "text"}
                    color={item.cta ? "primary" : "inherit"}
                    sx={{
                      mx: 0.5,
                      px: 2,
                      py: 1,
                      color: item.cta ? 'white' : theme.palette.text.primary,
                      borderRadius: 1,
                      "&:hover": {
                        backgroundColor: item.cta
                          ? theme.palette.primary.dark
                          : alpha(theme.palette.primary.main, 0.08),
                      },
                    }}
                  >
                    {item.title}
                  </Button>
                </Link>
              )
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;