import {
	Box,
	Typography,
	Button,
	Grow,
	Fade,
} from "@mui/material";
import { Link } from "react-router-dom";
//icon imports
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MergeIcon from "@mui/icons-material/Merge";
import AutomationIcon from "@mui/icons-material/AutoFixHigh";
//themes

//images
import heroBackgroundImage from "images/aiPhotos/21.jpg";
import reasonsBgImage from "images/aiPhotos/6.jpg";

//react imports
import { useState, useEffect, useRef } from "react";

import { FeaturesHero, ReasonsToChooseSection, KeyFeatureCards} from "pages/components";

const keyFeatures = [
	{
		title: "Intelligent Workflow Automation",
		subtitle: "Streamline processes by automating repetitive tasks across departments with AI that learns and adapts to your specific needs.",
		icon: <ModeOfTravelIcon fontSize="large" />,
		color: "rgb(80, 182, 255)",
	},
	{
		title: "Enterprise Integration",
		subtitle: "Seamlessly connect AI solutions with your existing systems and databases for frictionless information flow and operational continuity.",
		icon: <MergeIcon fontSize="large" />,
		color: "rgb(248, 140, 85)",
	},
	{
		title: "Actionable Insights",
		subtitle: "Transform raw data into strategic business intelligence with automated analytics that surface patterns, trends, and opportunities.",
		icon: <QueryStatsIcon fontSize="large" />,
		color: "rgb(38, 173, 40)",
	},
];

const automationBenefits = [
	{
		title: "Enhanced Efficiency",
		subtitle:
			"Reduce manual work by up to 80% through intelligent automation of repetitive processes.",
	},
	{
		title: "Cost Optimization",
		subtitle:
			"Lower operational costs while improving quality through consistent, error-free AI-driven workflows.",
	},
	{
		title: "Competitive Advantage",
		subtitle:
			"Deploy solutions that adapt to market changes and evolving requirements in real-time.",
	},
	{
		title: "Expert Implementation",
		subtitle:
			"Our specialized team ensures seamless deployment with minimal disruption to your operations.",
	},
];

const AutomatedSolutionsHero = {
	title: "AI Solutions & Integration",
	subtitle: "Streamline Operations with Intelligent Automation",
	content: `Transform your organization with purpose-built AI solutions that automate workflows, integrate seamlessly with existing systems, and deliver actionable insights. Our approach combines cutting-edge technology with practical implementation to drive measurable business outcomes.`,
	bgImage: heroBackgroundImage,
};


const AutomatedAISolutionsPage = () => {
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.5 }
		);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);
	return (
		<Box>
			<FeaturesHero {...AutomatedSolutionsHero} />

			<KeyFeatureCards 
				data={keyFeatures} 
				title="How Our Solutions Work"
				subtitle="Powerful AI capabilities designed to transform your business operations"
			/>

			<ReasonsToChooseSection
				title="Why Choose Our AI Solutions"
				subtitle="Experience the benefits of intelligent automation tailored to your business needs"
				reasons={automationBenefits}
				bgImage={reasonsBgImage}
			/>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					py: 10,
					px: 3,
					maxWidth: 800,
					mx: "auto",
					textAlign: "center",
				}}
				ref={ref}
			>
				<Fade
					in={isVisible}
					timeout={800}
				>
					<Typography
						variant="h5"
						sx={{ 
							fontWeight: 600, 
							mb: 4,
							lineHeight: 1.5 
						}}
					>
						Ready to transform your business with intelligent automation?
					</Typography>
				</Fade>
				<Grow
					in={isVisible}
					timeout={1200}
				>
					<Link
						to="/contact"
						style={{
							textDecoration: "none",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ 
								px: 4, 
								py: 1.5,
								fontWeight: 600,
								borderRadius: 2,
							}}
						>
							Get Started
						</Button>
					</Link>
				</Grow>
			</Box>
		</Box>
	);
};
export default AutomatedAISolutionsPage;