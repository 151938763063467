const light = {
	alternate: {
		main: "#F8F9FA",
		dark: "#F1F3F5",
	},
	cardShadow: "rgba(23, 70, 161, .08)",
	mode: "light",
	primary: {
		main: "#9C27B0",  /* Material UI Purple */
		light: "#BA68C8",
		dark: "#7B1FA2",
		contrastText: "#fff",
	},
	secondary: {
		main: "#FF5722",
		light: "#FF8A65",
		dark: "#E64A19",
		contrastText: "#fff",
	},
	text: {
		primary: "#212121",
		secondary: "#505050", // Darkened for better contrast
	},
	divider: "rgba(0, 0, 0, 0.12)",
	background: {
		paper: "#ffffff",
		default: "#ffffff",
		level2: "#F8F9FA",
		level1: "#ffffff",
	},
};

const dark = {
	alternate: {
		main: "#1A2027",
		dark: "#151A1F",
	},
	cardShadow: "rgba(0, 0, 0, .16)",
	common: {
		black: "#000",
		white: "#fff",
	},
	mode: "dark",
	primary: {
		main: "#1976D2",
		light: "#42A5F5",
		dark: "#1565C0",
		contrastText: "#fff",
	},
	secondary: {
		main: "#FF5722",
		light: "#FF8A65",
		dark: "#E64A19",
		contrastText: "#fff",
	},
	text: {
		primary: "#EEEEEF",
		secondary: "#AEB0B4",
	},
	divider: "rgba(255, 255, 255, 0.12)",
	background: {
		paper: "#222222",
		default: "#171717",
		level2: "#2A2A2A",
		level1: "#2D2D2D",
	},
};

export { light, dark };
