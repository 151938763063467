import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import Container from "../../components/Container";
import { Footer, NavBar } from "./components";

const Main = ({
  children,
  colorInvert = false,
  bgcolor = "transparent",
  showLogin,
  showFooter = true,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ 
      display: "flex", 
      flexDirection: "column", 
      minHeight: "100vh",
      bgcolor: theme.palette.background.default,
    }}>
      <NavBar
        bgcolor={bgcolor}
        showLogin={showLogin}
      />
      <Box sx={{ flex: '1 1 auto' }}>
        <main>{children}</main>
      </Box>
      
      {showFooter && (
        <Box component="footer" sx={{ 
          bgcolor: theme.palette.background.level2,
          borderTop: `1px solid ${theme.palette.divider}`,
          py: 4,
        }}>
          <Container>
            <Footer />
          </Container>
        </Box>
      )}
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
  showLogin: PropTypes.bool,
  showFooter: PropTypes.bool,
};

export default Main;