import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Box, Button, Typography, Container } from "@mui/material";
import { Link } from "react-router-dom";

const FeaturesHero = ({ title, subtitle, content, bgImage }) => {
	const theme = useTheme();
	const [animate, setAnimate] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setAnimate(true), 200);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
				alignItems: "center",
				minHeight: "90vh",
				overflow: "hidden",
				backgroundColor: theme.palette.background.level2,
				"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.85)), url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					zIndex: 0,
				},
			}}
		>
			<Container
				maxWidth="md"
				sx={{ position: "relative", zIndex: 1 }}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						textAlign: "center",
						py: { xs: 8, md: 12 },
						opacity: animate ? 1 : 0,
						transform: animate ? "translateY(0)" : "translateY(20px)",
						transition: "opacity 1s ease, transform 1s ease",
					}}
				>
					<Typography
						variant="h2"
						component="h1"
						sx={{
							fontWeight: 800,
							fontSize: { xs: "2.5rem", sm: "3.5rem" },
							color: "white",
							mb: 3,
							textShadow: "0 2px 10px rgba(0,0,0,0.3)",
							position: "relative",
							"&::after": {
								content: '""',
								position: "absolute",
								bottom: -16,
								left: "50%",
								transform: "translateX(-50%)",
								width: 100,
								height: 4,
								borderRadius: 2,
								backgroundColor: theme.palette.primary.main,
							},
						}}
					>
						{title}
					</Typography>

					<Typography
						variant="h5"
						sx={{
							mt: 4,
							color: theme.palette.secondary.light,
							fontWeight: 600,
							mb: 4,
							opacity: animate ? 1 : 0,
							transform: animate ? "translateY(0)" : "translateY(15px)",
							transition: "opacity 1.2s ease, transform 1.2s ease",
						}}
					>
						{subtitle}
					</Typography>

					<Typography
						variant="body1"
						sx={{
							color: "rgba(255, 255, 255, 0.85)",
							lineHeight: 1.8,
							fontSize: { xs: "1rem", sm: "1.1rem" },
							maxWidth: "90%",
							mb: 5,
							opacity: animate ? 1 : 0,
							transform: animate ? "translateY(0)" : "translateY(15px)",
							transition: "opacity 1.4s ease, transform 1.4s ease",
						}}
					>
						{content}
					</Typography>

					<Box
						sx={{
							opacity: animate ? 1 : 0,
							transform: animate ? "translateY(0)" : "translateY(15px)",
							transition: "opacity 1.6s ease, transform 1.6s ease",
						}}
					>
						<Button
							component={Link}
							to="/contact"
							variant="contained"
							color="primary"
							size="large"
							sx={{
								fontWeight: 600,
								px: 4,
								py: 1.5,
								borderRadius: 2,
								textTransform: "none",
								boxShadow: "0 4px 14px rgba(0,0,0,0.25)",
								"&:hover": {
									transform: "translateY(-3px)",
									boxShadow: "0 6px 20px rgba(0,0,0,0.25)",
								},
							}}
						>
							Get In Touch
						</Button>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default FeaturesHero;
