import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const Container = ({ children, paddingX, paddingY, maxWidth, ...rest }) => {
  const theme = useTheme();
  
  return (
    <Box
      maxWidth={maxWidth || { xs: 1, sm: 720, md: 1200 }}
      width={1}
      margin={'0 auto'}
      paddingX={paddingX || { xs: 2, sm: 4, md: 4 }}
      paddingY={paddingY || { xs: 4, sm: 6, md: 8 }}
      sx={{
        position: 'relative',
        zIndex: 1,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  paddingX: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  paddingY: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  maxWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default Container;