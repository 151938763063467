import FeaturesHero from "pages/components/FeaturesHero";
import heroBackgroundImage from "images/aiPhotos/22.jpg";
import { ReasonsToChooseSection, KeyFeatureCards, CarouselInfoSection, InfoListSection } from "pages/components";
import carouselImg1 from "images/aiPhotos/31.jpg";
import carouselImg2 from "images/aiPhotos/32.jpg";
import carouselImg3 from "images/aiPhotos/33.jpg";
import carouselImg4 from "images/aiPhotos/34.jpg";
import sectionImg from "images/aiPhotos/21.jpg";

const heroContent = {
	title: "Experienced AI Engineers",
	subtitle: "Partner with Experts to Drive AI Success",
	content: `At Pillars AI, our team of experienced AI engineers brings deep technical expertise and hands-on experience to deliver cutting-edge AI solutions. From development to deployment, we provide the talent and skills needed to achieve your AI goals.`,
	bgImage: heroBackgroundImage,
};
const reasonsToWorkWithOurEngineers = {
	title: "Why Work with Our Engineers?",
	subtitle: "Partner with a technical team that delivers value at every stage of your AI journey",
	reasons: [
		{
			title: "Proven Expertise",
			subtitle:
				"Deep technical knowledge across machine learning, NLP, and computer vision domains.",
		},
		{
			title: "Custom Solutions",
			subtitle: "Tailored AI systems that address your specific business challenges.",
		},
		{
			title: "End-to-End Support",
			subtitle: "Comprehensive assistance from initial concept through deployment and scaling.",
		},
		{
			title: "Future-Proof",
			subtitle:
				"Scalable architectures that grow with your business needs and adapt to new technologies.",
		},
	],
	bgImage: heroBackgroundImage,
};

const whatWeOffer = [
	{
		title: "Custom AI Development",
		subtitle: "Domain-specific models tailored to your unique business needs with seamless workflow integration.",
		color: "rgb(80, 182, 255)",
		icon: (
			<svg
				height={24}
				width={24}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
				/>
			</svg>
		),
	},
	{
		title: "Technical Excellence",
		subtitle: "Expertise in machine learning, NLP, computer vision, and data engineering for robust solutions.",
		color: "rgb(186, 92, 254)",
		icon: (
			<svg
				height={24}
				width={24}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
				/>
			</svg>
		),
	},
	{
		title: "Optimization & Support",
		subtitle: "Continuous performance improvements and maintenance for scalable, efficient AI systems.",
		color: "rgb(38, 173, 40)",
		icon: (
			<svg
				height={24}
				width={24}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M13 10V3L4 14h7v7l9-11h-7z"
				/>
			</svg>
		),
	},
];

const ourEngineersExcelInContent = {
	title: "Our Engineers Excel In",
	items: [
		{
			keyword: "Machine Learning",
			description:
				"Supervised, unsupervised, reinforcement learning, and more.",
			image: carouselImg1,
		},
		{
			keyword: "Natural Language Processing",
			description: "Text analysis, sentiment analysis, chatbots, and more.",
			image: carouselImg2,
		},
		{
			keyword: "Computer Vision",
			description:
				"Image recognition, object detection, video analysis, and more.",
			image: carouselImg3,
		},

		{
			keyword: "Data Engineering",
			description: "Building Robust piplelines for AI-driven insights",
			image: carouselImg4,
		},
	],
};

const collaborativeApproachSectionContent = {
	title: "Collaborative Approach",
	image:sectionImg,
	items: [
		{
			keyword: "Team Integration",
			description: "Our engineers work seamlessly with your internal teams.",
		},
		{
			keyword: "Agile Methodologies",
			description:
				"Rapid iterations ensure quick deliveryt of imapctful solutions",
		},
		{
			keyword: "Knowledge Sharing",
			description: "Upskill teams to maintain and expand AI systems",
		},
	],
};

const AIEngineersPage = () => {
	return (
		<div>
			<FeaturesHero {...heroContent} />
			<KeyFeatureCards 
				data={whatWeOffer} 
				title="What We Offer"
				subtitle="Our team delivers end-to-end AI solutions with technical excellence and ongoing support"
			/>
			<CarouselInfoSection {...ourEngineersExcelInContent} />
			<InfoListSection {...collaborativeApproachSectionContent} />
			<ReasonsToChooseSection {...reasonsToWorkWithOurEngineers} />
		</div>
	);
};
export default AIEngineersPage;
