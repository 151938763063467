import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { Fade } from "@mui/material";

const Form = () => {
  const theme = useTheme();

  return (
    <Box>
     

      <Box
        component={"form"}
        noValidate
        autoComplete="off"
        sx={{
          mb: 3,
          maxWidth: 480,
        }}
      >
        
      </Box>
      
  
    </Box>
  );
};

export default Form;