import { useEffect, useRef, useState } from "react";
import {
	Box,
	Container,
	Grid,
	Typography,
	useTheme,
	Card,
	CardContent,
	Divider,
} from "@mui/material";
import { Fade } from "@mui/material";

const ReasonsToChooseSection = ({ title, subtitle, reasons, bgImage }) => {
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);
	const theme = useTheme();

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.1 }
		);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);

	return (
		<Box
			sx={{
				position: "relative",
				py: { xs: 8, md: 12 },
				"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundAttachment: "fixed",
					zIndex: 0,
				},
			}}
			ref={ref}
		>
			<Container
				maxWidth="lg"
				sx={{ position: "relative", zIndex: 1 }}
			>
				<Box sx={{ mb: 8, textAlign: "center", maxWidth: 800, mx: "auto" }}>
					<Typography
						variant="h3"
						component="h2"
						sx={{
							fontWeight: 700,
							color: "white",
							mb: 3,
						}}
					>
						{title}
					</Typography>
					
					{subtitle && (
						<Typography
							variant="subtitle1"
							sx={{
								color: "rgba(255, 255, 255, 0.85)",
								fontWeight: 400,
								lineHeight: 1.7,
								mb: 4,
							}}
						>
							{subtitle}
						</Typography>
					)}
				</Box>

				<Grid
					container
					spacing={4}
					justifyContent="center"
				>
					{reasons.map((reason, i) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							key={i}
						>
							<Fade
								in={isVisible}
								timeout={500}
								style={{ transitionDelay: `${i * 150}ms` }}
							>
								<Card
									elevation={0}
									sx={{
										height: "100%",
										backgroundColor: theme.palette.mode === "dark" 
											? "rgba(30, 30, 30, 0.9)" 
											: "rgba(255, 255, 255, 0.95)",
										borderRadius: 2,
										overflow: "hidden",
										transition: "transform 0.3s ease, box-shadow 0.3s ease",
										boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
										"&:hover": {
											transform: "translateY(-8px)",
											boxShadow: "0 15px 30px rgba(0, 0, 0, 0.2)",
										},
									}}
								>
									<CardContent sx={{ p: 3 }}>
										<Box 
											sx={{ 
												display: 'flex', 
												alignItems: 'center', 
												mb: 3 
											}}
										>
											<Box
												sx={{
													width: 40,
													height: 40,
													borderRadius: '50%',
													backgroundColor: theme.palette.primary.main,
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													color: 'white',
													fontWeight: 700,
													mr: 2,
													fontSize: '1.2rem',
												}}
											>
												{i + 1}
											</Box>
											<Typography
												variant="h6"
												component="h3"
												sx={{
													fontWeight: 700,
													color: theme.palette.text.primary,
												}}
											>
												{reason.title}
											</Typography>
										</Box>

										<Typography
											variant="body2"
											sx={{
												color: theme.palette.text.secondary,
												lineHeight: 1.7,
											}}
										>
											{reason.subtitle}
										</Typography>
									</CardContent>
								</Card>
							</Fade>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
};

export default ReasonsToChooseSection;
