import React from "react";
import FullScreenHeroWithSubscriptionForm from "../components/FullScreenHeroWithSubscriptionForm";
import FeatureCardsWithColorfullIconsAndLearnMoreLink from "../components/FeatureCardsWithColorfullIconsAndLearnMoreLink";
import WithSimpleCards from "../components/WithSimpleCards";
import { useTheme } from "@mui/material/styles";
import {
	Box,
	Container,
	Typography,
	Grid,
	Button,
	Card,
	CardContent,
	Avatar,
} from "@mui/material";
import computerBg from "images/aiPhotos/38.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

const Home = () => {
	const theme = useTheme();

	return (
		<Box>
			{/* Hero Section */}
			<FullScreenHeroWithSubscriptionForm />

			{/* Services Section */}
			<FeatureCardsWithColorfullIconsAndLearnMoreLink />

			{/* About Us Section */}
			<Box
				sx={{
					bgcolor: theme.palette.background.level2,
					py: { xs: 6, md: 10 },
				}}
			>
				<Container maxWidth="lg">
					<Grid
						container
						spacing={4}
						alignItems="center"
					>
						<Grid
							item
							xs={12}
							md={6}
						>
							<Typography
								component="h2"
								variant="h3"
								sx={{
									fontWeight: 700,
									mb: 3,
									color: theme.palette.text.primary,
								}}
							>
								About Pillar1
							</Typography>

							<Typography
								variant="body1"
								sx={{
									color: theme.palette.text.secondary,
									mb: 3,
									lineHeight: 1.8,
								}}
							>
								At Pillar1, we are passionate about leveraging cutting-edge AI
								and IT solutions to tackle complex challenges and deliver
								innovative results. Our team comprises highly skilled
								professionals with extensive experience in the AI landscape,
								dating back to when "machine learning" first emerged as a
								transformative concept.
							</Typography>

							<Typography
								variant="body1"
								sx={{
									color: theme.palette.text.secondary,
									mb: 4,
									lineHeight: 1.8,
								}}
							>
								Whether you require a comprehensive end-to-end solution—from
								initial ideation to full deployment—or support with a specific
								aspect such as refining requirements, developing models, or
								prototyping, we are equipped to meet your needs with precision
								and expertise.
							</Typography>

							<Button
								component={Link}
								to="/contact"
								variant="outlined"
								color="primary"
								endIcon={<ArrowForwardIcon />}
								sx={{ borderRadius: 2, px: 3, py: 1 }}
							>
								Get in Touch
							</Button>
						</Grid>

						<Grid
							item
							xs={12}
							md={6}
						>
							<Box
								sx={{
									position: "relative",
									height: 400,
									borderRadius: 4,
									overflow: "hidden",
									boxShadow: "0 16px 40px rgba(0,0,0,0.12)",
									"&::before": {
										content: '""',
										position: "absolute",
										top: 0,
										left: 0,
										width: "100%",
										height: "100%",
										backgroundImage: `url(${computerBg})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
										opacity: 0.9,
									},
								}}
							/>
						</Grid>
					</Grid>
				</Container>
			</Box>

			{/* Team Section */}
			<Box
				id="team"
				sx={{
					bgcolor: theme.palette.background.default,
					py: { xs: 6, md: 10 },
				}}
			>
				<Container>
					<Box sx={{ textAlign: "center", mb: 6, maxWidth: 800, mx: "auto" }}>
						<Typography
							variant="h3"
							component="h2"
							sx={{
								fontWeight: 700,
								mb: 2,
								color: theme.palette.text.primary,
							}}
						>
							Our Expert Team
						</Typography>

						<Typography
							variant="h6"
							component="p"
							sx={{
								color: theme.palette.text.secondary,
								mb: 2,
							}}
						>
							We're a dedicated team of AI specialists with decades of combined
							experience building innovative solutions for complex problems.
						</Typography>
					</Box>

					<WithSimpleCards />
				</Container>
			</Box>
		</Box>
	);
};

export default Home;
