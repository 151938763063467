import { useState, useEffect } from "react";
import {
	Box,
	Button,
	Typography,
	useTheme,
	Grid,
	TextField,
	Container,
	Card,
	CardContent,
	Divider,
	Paper,
	Alert,
	Snackbar,
	CircularProgress,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import heroBackgroundImage from "images/aiPhotos/9.jpg";
import emailjs from "emailjs-com";

const ServiceID = "service_6z495vr";
const templateID = "template_pta4j6d";
const userID = "eu5oNFR-km4D26Cwp";

const ContactForm = () => {
	const theme = useTheme();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		company: "",
		websiteURL: "",
		message: "",
	});

	const resetForm = () => {
		setFormData({
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			company: "",
			websiteURL: "",
			message: "",
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		setError(false);

		emailjs
			.send(
				ServiceID,
				templateID,
				{
					firstName: formData.firstName + " " + formData.lastName,
					email: formData.email,
					phoneNumber: formData.phoneNumber,
					company: formData.company,
					websiteURL: formData.websiteURL,
					message: formData.message,
				},
				userID
			)
			.then(
				(response) => {
					setSuccess(true);
					resetForm();
				},
				(err) => {
					console.error("FAILED...", err);
					setError(true);
				}
			)
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleCloseSuccess = () => {
		setSuccess(false);
	};

	const handleCloseError = () => {
		setError(false);
	};

	return (
		<Card
			elevation={0}
			sx={{
				borderRadius: 3,
				backgroundColor: "rgba(255, 255, 255, 0.95)",
				overflow: "visible",
				position: "relative",
				"&::before": {
					content: '""',
					position: "absolute",
					top: -8,
					left: -8,
					right: -8,
					bottom: -8,
					zIndex: -1,
					background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
					borderRadius: 4,
					opacity: 0.7,
				},
			}}
		>
			<CardContent sx={{ p: { xs: 3, md: 4 } }}>
				<Typography
					variant="h5"
					component="h2"
					sx={{
						mb: 3,
						color: theme.palette.primary.main,
						fontWeight: 600,
					}}
				>
					Send us a message
				</Typography>

				<Box
					component="form"
					onSubmit={handleSubmit}
					noValidate
					sx={{ mt: 1 }}
				>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
							sm={6}
						>
							<TextField
								name="firstName"
								label="First Name"
								fullWidth
								required
								variant="outlined"
								value={formData.firstName}
								onChange={handleChange}
								sx={{
									"& .MuiInputBase-input": {
										color: theme.palette.text.primary,
									},
									"& .MuiFormLabel-root": {
										color: theme.palette.text.secondary,
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
						>
							<TextField
								name="lastName"
								label="Last Name"
								fullWidth
								required
								variant="outlined"
								value={formData.lastName}
								onChange={handleChange}
								sx={{
									"& .MuiInputBase-input": {
										color: theme.palette.text.primary,
									},
									"& .MuiFormLabel-root": {
										color: theme.palette.text.secondary,
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
						>
							<TextField
								name="email"
								label="Email Address"
								type="email"
								fullWidth
								required
								variant="outlined"
								value={formData.email}
								onChange={handleChange}
								sx={{
									"& .MuiInputBase-input": {
										color: theme.palette.text.primary,
									},
									"& .MuiFormLabel-root": {
										color: theme.palette.text.secondary,
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
						>
							<TextField
								name="phoneNumber"
								label="Phone Number"
								fullWidth
								variant="outlined"
								value={formData.phoneNumber}
								onChange={handleChange}
								sx={{
									"& .MuiInputBase-input": {
										color: theme.palette.text.primary,
									},
									"& .MuiFormLabel-root": {
										color: theme.palette.text.secondary,
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
						>
							<TextField
								name="company"
								label="Company"
								fullWidth
								variant="outlined"
								value={formData.company}
								onChange={handleChange}
								sx={{
									"& .MuiInputBase-input": {
										color: theme.palette.text.primary,
									},
									"& .MuiFormLabel-root": {
										color: theme.palette.text.secondary,
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
						>
							<TextField
								name="websiteURL"
								label="Website URL"
								fullWidth
								variant="outlined"
								value={formData.websiteURL}
								onChange={handleChange}
								sx={{
									"& .MuiInputBase-input": {
										color: theme.palette.text.primary,
									},
									"& .MuiFormLabel-root": {
										color: theme.palette.text.secondary,
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								name="message"
								label="Message"
								multiline
								rows={5}
								fullWidth
								required
								variant="outlined"
								value={formData.message}
								onChange={handleChange}
								placeholder="Tell us about your project..."
								sx={{
									"& .MuiInputBase-input": {
										color: theme.palette.text.primary,
									},
									"& .MuiFormLabel-root": {
										color: theme.palette.text.secondary,
									},
								}}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								fullWidth
								size="large"
								disabled={isSubmitting}
								sx={{
									py: 1.5,
									fontWeight: 600,
									mt: 1,
								}}
							>
								{isSubmitting ? (
									<CircularProgress
										size={24}
										color="inherit"
									/>
								) : (
									"Send Message"
								)}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</CardContent>

			<Snackbar
				open={success}
				autoHideDuration={6000}
				onClose={handleCloseSuccess}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={handleCloseSuccess}
					severity="success"
					variant="filled"
					sx={{ width: "100%" }}
				>
					Message sent successfully! We'll be in touch soon.
				</Alert>
			</Snackbar>

			<Snackbar
				open={error}
				autoHideDuration={6000}
				onClose={handleCloseError}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={handleCloseError}
					severity="error"
					variant="filled"
					sx={{ width: "100%" }}
				>
					Failed to send message. Please try again later.
				</Alert>
			</Snackbar>
		</Card>
	);
};

const ContactPage = () => {
	const theme = useTheme();
	const [animate, setAnimate] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setAnimate(true), 200);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Box
			sx={{
				position: "relative",
				py: { xs: 6, md: 10 },
				"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.85)), url(${heroBackgroundImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					zIndex: -1,
				},
			}}
		>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={4}
					alignItems="center"
				>
					<Grid
						item
						xs={12}
						md={5}
						sx={{
							opacity: animate ? 1 : 0,
							transform: animate ? "translateX(0)" : "translateX(-20px)",
							transition: "opacity 0.8s ease, transform 0.8s ease",
						}}
					>
						<Typography
							variant="h2"
							component="h1"
							sx={{
								fontWeight: 700,
								mb: 3,
								fontSize: { xs: "2.5rem", md: "3.25rem" },
							}}
						>
							Get in Touch
						</Typography>

						<Typography
							variant="h6"
							sx={{
								mb: 4,
								lineHeight: 1.6,
							}}
						>
							Let's discuss how we can help transform your business with AI
							solutions tailored to your needs.
						</Typography>

						<Box sx={{ mb: 5 }}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									mb: 3,
									opacity: animate ? 1 : 0,
									transform: animate ? "translateY(0)" : "translateY(10px)",
									transition: "opacity 1s ease, transform 1s ease",
									transitionDelay: "0.2s",
								}}
							>
								<Paper
									elevation={0}
									sx={{
										p: 1.5,
										mr: 2,
										borderRadius: 2,
										backgroundColor: theme.palette.primary.main,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<EmailIcon sx={{ color: "white" }} />
								</Paper>
								<Box>
									<Typography
										variant="subtitle2"
										sx={{ fontWeight: 600 }}
									>
										Email Us
									</Typography>
									<Typography
										variant="body2"
										sx={{ color: "rgba(0, 0, 0, 0.7)" }}
									>
										pillar1@gmail.com
									</Typography>
								</Box>
							</Box>

							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									mb: 3,
									opacity: animate ? 1 : 0,
									transform: animate ? "translateY(0)" : "translateY(10px)",
									transition: "opacity 1s ease, transform 1s ease",
									transitionDelay: "0.3s",
								}}
							>
								<Paper
									elevation={0}
									sx={{
										p: 1.5,
										mr: 2,
										borderRadius: 2,
										backgroundColor: theme.palette.primary.main,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<PhoneIcon sx={{ color: "white" }} />
								</Paper>
								<Box>
									<Typography
										variant="subtitle2"
										sx={{ fontWeight: 600 }}
									>
										Call Us
									</Typography>
									<Typography
										variant="body2"
										sx={{ color: "rgba(0, 0, 0, 0.7)" }}
									>
										+1 (555) 123-4567
									</Typography>
								</Box>
							</Box>

							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									opacity: animate ? 1 : 0,
									transform: animate ? "translateY(0)" : "translateY(10px)",
									transition: "opacity 1s ease, transform 1s ease",
									transitionDelay: "0.4s",
								}}
							>
								<Paper
									elevation={0}
									sx={{
										p: 1.5,
										mr: 2,
										borderRadius: 2,
										backgroundColor: theme.palette.primary.main,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<LocationOnIcon sx={{ color: "white" }} />
								</Paper>
								<Box>
									<Typography
										variant="subtitle2"
										sx={{ fontWeight: 600 }}
									>
										Our Location
									</Typography>
									<Typography
										variant="body2"
										sx={{ color: "rgba(0, 0, 0, 0.7)" }}
									>
										Charlotte, NC 28262
									</Typography>
								</Box>
							</Box>
						</Box>

						<Divider sx={{ borderColor: "rgba(255, 255, 255, 0.1)", my: 4 }} />

						<Typography
							variant="body2"
							sx={{
								color: "rgba(0, 0, 0, 0.6)",
								fontStyle: "italic",
							}}
						>
							We typically respond to inquiries within 24 hours.
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						md={7}
						sx={{
							opacity: animate ? 1 : 0,
							transform: animate ? "translateX(0)" : "translateX(20px)",
							transition: "opacity 0.8s ease, transform 0.8s ease",
						}}
					>
						<ContactForm />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default ContactPage;
