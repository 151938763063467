// Modern color scheme
const primaryBlue = "#1976D2"; // Material UI blue
const primaryLight = "#42A5F5";
const primaryDark = "#1565C0";

const secondaryOrange = "#FF5722"; // Material UI deep orange
const secondaryLight = "#FF8A65";
const secondaryDark = "#E64A19";

const neutralGrey = "#F5F5F5";
const neutralGreyDark = "#E0E0E0";

const titleColor = "#212121";
const bodyColor = "#505050"; // Darkened for better contrast

// Subtle gradient for backgrounds
const lightGradient =
	"linear-gradient(180deg, rgba(248, 249, 250, 0.6) 0%, rgba(255, 255, 255, 1) 100%)";
const darkGradient =
	"linear-gradient(180deg, rgba(23, 23, 23, 1) 0%, rgba(33, 33, 33, 1) 100%)";

export {
	primaryBlue,
	primaryLight,
	primaryDark,
	secondaryOrange,
	secondaryLight,
	secondaryDark,
	neutralGrey,
	neutralGreyDark,
	lightGradient,
	darkGradient,
	titleColor,
	bodyColor,
};
