import {
	FeaturesHero,
	ReasonsToChooseSection,
	KeyFeatureCards,
	HorizontalTimeline,
} from "pages/components";
import {
	Box,
	Typography,
	Grid,
	Button,
	Card,
	CardContent,
	Fade,
} from "@mui/material";
import { Link } from "react-router-dom";

//images
import herobg from "images/aiPhotos/14.jpg";
import reasonsBg from "images/aiPhotos/19.jpg";

//themes
import { useTheme } from "@mui/material";
//icons
import FilterDramaIcon from "@mui/icons-material/FilterDrama";
import TuneIcon from "@mui/icons-material/Tune";
import KeyIcon from "@mui/icons-material/Key";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
//react imports
import { useState, useEffect, useRef } from "react";

//DATA
const heroContent = {
	title: "Private AI & Security",
	subtitle: "Deploy AI with Complete Data Control",
	bgImage: herobg,
	content:
		"Deploy customized Large Language Models (LLMs) within your secure infrastructure. Maintain full data sovereignty, regulatory compliance, and the ability to fine-tune models for your industry-specific needs—all without compromising on AI capabilities.",
};

const securityBenefits = [
	{
		title: "Complete Data Control",
		subtitle:
			"Your data never leaves your environment, ensuring compliance with regulations like GDPR, HIPAA, and CCPA.",
	},
	{
		title: "Custom-Tuned Models",
		subtitle:
			"Models fine-tuned for your specific domain, ensuring relevance, accuracy and business alignment.",
	},
	{
		title: "Deployment Flexibility",
		subtitle:
			"Choose your environment—on-premises, air-gapped, or private cloud on AWS, Azure, or GCP.",
	},
	{
		title: "Enterprise Integration",
		subtitle:
			"Seamlessly connect with existing systems while maintaining security protocols and governance.",
	},
];

const keyFeatures = [
	{
		title: "On-Premise Deployment",
		subtitle: "Secure AI within your infrastructure with no external data transmission.",
		icon: <FilterDramaIcon fontSize="large" />,
		color: "#4285F4",
	},
	{
		title: "Domain-Specific Training",
		subtitle: "Models customized to your industry terminology and specific use cases.",
		icon: <TuneIcon fontSize="large" />,
		color: "#0F9D58",
	},
	{
		title: "Enterprise Security",
		subtitle: "Comprehensive security controls with audit trails and access management.",
		icon: <VerifiedUserIcon fontSize="large" />,
		color: "#DB4437",
	},
];

const deploymentProcess = {
	title: "Deployment Process",
	steps: [
		{
			title: "Assessment",
			description: "Technical evaluation of your infrastructure and security requirements.",
			icon: <AssessmentIcon />,
			color: "#4285F4",
		},
		{
			title: "Implementation",
			description: "Secure deployment in your chosen environment with minimal disruption.",
			icon: <FlightTakeoffIcon />,
			color: "#0F9D58",
		},
		{
			title: "Customization",
			description: "Model fine-tuning and optimization for your specific use cases.",
			icon: <TuneIcon />,
			color: "#DB4437",
		},
		{
			title: "Integration Support",
			description: "Ongoing assistance with system integration and performance optimization.",
			icon: <HelpCenterIcon />,
			color: "#F4B400",
		},
	],
};

const UseCaseSection = () => {
	const theme = useTheme();
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.2 }
		);
		
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);

	const useCases = [
		{
			title: "Financial Services",
			description: "Process sensitive financial data with models that never expose client information to third parties."
		},
		{
			title: "Healthcare",
			description: "Analyze patient records and medical data while maintaining full HIPAA compliance and privacy."
		},
		{
			title: "Government",
			description: "Handle classified information with secure models in controlled environments."
		},
		{
			title: "Legal Services",
			description: "Process confidential legal documents with models that maintain attorney-client privilege."
		}
	];

	return (
		<Box 
			sx={{ 
				py: 8, 
				backgroundColor: theme.palette.mode === "dark" ? "rgba(20, 20, 20, 0.8)" : "#f8f9fa" 
			}}
			ref={ref}
		>
			<Box sx={{ maxWidth: 1200, mx: "auto", px: 3 }}>
				<Typography
					variant="h4"
					component="h2"
					align="center"
					sx={{
						fontWeight: 700,
						mb: 6,
						color: theme.palette.text.primary,
					}}
				>
					Industry Applications
				</Typography>

				<Grid container spacing={4}>
					{useCases.map((useCase, i) => (
						<Grid item xs={12} sm={6} key={i}>
							<Fade 
								in={isVisible} 
								timeout={1000}
								style={{ transitionDelay: `${i * 150}ms` }}
							>
								<Card 
									elevation={0}
									sx={{
										height: "100%",
										borderRadius: 2,
										boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
										transition: "transform 0.3s ease",
										"&:hover": {
											transform: "translateY(-8px)",
											boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
										},
									}}
								>
									<CardContent sx={{ p: 3 }}>
										<Typography
											variant="h6"
											sx={{
												fontWeight: 700,
												mb: 2,
												color: theme.palette.primary.main,
											}}
										>
											{useCase.title}
										</Typography>
										<Typography
											variant="body2"
											sx={{
												color: theme.palette.text.secondary,
												lineHeight: 1.6,
											}}
										>
											{useCase.description}
										</Typography>
									</CardContent>
								</Card>
							</Fade>
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

const PrivateDeploymentPage = () => {
	const theme = useTheme();
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.5 }
		);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);

	return (
		<Box>
			<FeaturesHero {...heroContent} />
			
			<KeyFeatureCards 
				data={keyFeatures} 
				title="Key Security Features"
				subtitle="Enterprise-grade security for your most sensitive AI applications"
			/>
			
			<UseCaseSection />
			
			<HorizontalTimeline content={deploymentProcess} />
			
			<ReasonsToChooseSection
				title="Why Choose Our Private AI Solution"
				subtitle="Enterprise-grade security without sacrificing AI performance"
				reasons={securityBenefits}
				bgImage={reasonsBg}
			/>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					py: 10,
					px: 3,
					maxWidth: 800,
					mx: "auto",
					textAlign: "center",
				}}
				ref={ref}
			>
				<Fade
					in={isVisible}
					timeout={800}
				>
					<Typography
						variant="h5"
						sx={{ 
							fontWeight: 600, 
							mb: 4,
							lineHeight: 1.5 
						}}
					>
						Ready to deploy secure AI within your organization?
					</Typography>
				</Fade>
				<Fade
					in={isVisible}
					timeout={1200}
				>
					<Link
						to="/contact"
						style={{
							textDecoration: "none",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ 
								px: 4, 
								py: 1.5,
								fontWeight: 600,
								borderRadius: 2,
							}}
						>
							Schedule a Security Consultation
						</Button>
					</Link>
				</Fade>
			</Box>
		</Box>
	);
};
export default PrivateDeploymentPage;