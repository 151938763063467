import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import avatarAyman from "images/ayman.jpg";
import avatarPierre from "images/pierre.jpeg";
import avatarHadi from "images/hadi.jpg";
import avatarJorge from "images/jorge.jpg";
import avatarZoe from "images/zoe.jpeg";
import avatarHolden from "images/holden.png";
import { alpha } from "@mui/material/styles";
import { Fade } from "@mui/material";
import { useState, useEffect, useRef } from "react";

const teamMembers = [
  {
    name: "Pierre Arbajian, PhD",
    title: "Chief AI Architect",
    avatar: avatarPierre,
    about:
      "Pierre is our Senior Data Scientist and Chief AI Architect, bringing a wealth of experience in all things data and AI. He is passionate about studying and tinkering with LLMs, recognizing that AI holds the potential to revolutionize the world.",
  },
  {
    name: "Ayman Hajja, PhD",
    title: "Chief Technology Officer",
    avatar: avatarAyman,
    about:
      "Serves as our Chief Technology Officer, known for developing and deploying cutting-edge, highly complex solutions. With profound expertise in both theory and practice, Ayman leads implementations with precision and unwavering commitment to quality.",
  },
  {
    name: "Zoe Duah Yuehua",
    title: "Machine Learning Engineer",
    avatar: avatarZoe,
    about:
      "Yuehua (Zoe) Duan is a skilled Machine Learning Engineer and Data Scientist with a Ph.D. in Computer Science. She has extensive experience in AI, big data, and software development, contributing to impactful projects at SAS, Weibo.com, and Hanvon Technology.",
  },
  {
    name: "Jorge Monteagudo",
    title: "Front End Engineer, UI/UX Specialist",
    avatar: avatarJorge,
    about:
      "Jorge Monteagudo is a Front-End Engineer and UI/UX specialist with expertise in React, Next.js, and Node.js. With experience in AIs, APIs, and dynamic user interfaces, he excels at optimizing performance and delivering intuitive solutions.",
  },
  {
    name: "Hadi Ghattas",
    title: "Back End Engineer",
    avatar: avatarHadi,
    about:
      "Hadi is our skilled Backend Engineer, responsible for designing and maintaining server-side logic and ensuring seamless integration with frontend components. With deep expertise in backend technologies, databases, and APIs, Hadi builds robust and scalable solutions.",
  },
  {
    name: "Holden Mcmahan",
    title: "IT Analyst",
    avatar: avatarHolden,
    about:
      "Holden McMahan is an accomplished IT professional specializing in implementing cutting-edge AI technologies, including Generative AI and Large Language Models. With expertise in hardware and network setup, user research, and rapid problem-solving.",
  },
];

const WithSimpleCards = () => {
  const theme = useTheme();
  const ref = useRef(null);
  const [isVisible, setVisible] = useState(false);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );
    
    if (ref.current) {
      observer.observe(ref.current);
    }
    
    return () => observer.disconnect();
  }, []);

  return (
    <Box ref={ref}>
      <Grid container spacing={3}>
        {teamMembers.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Fade
              in={isVisible}
              timeout={800}
              style={{ transitionDelay: `${i * 150}ms` }}
            >
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 2,
                  boxShadow: theme.shadows[1],
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: theme.shadows[4],
                  },
                  overflow: 'hidden',
                }}
              >
                <Box 
                  sx={{ 
                    position: 'relative',
                    pt: 3,
                    pb: 2,
                    px: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={item.avatar}
                    alt={item.name}
                    sx={{
                      width: 96,
                      height: 96,
                      border: `4px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                      boxShadow: `0 4px 14px 0 ${alpha(theme.palette.primary.main, 0.2)}`,
                      mb: 2,
                    }}
                  />
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{
                      fontWeight: 700,
                      color: theme.palette.text.primary,
                      textAlign: 'center',
                      mb: 0.5,
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    sx={{
                      mb: 1,
                      textAlign: 'center',
                      fontWeight: 500,
                    }}
                  >
                    {item.title}
                  </Typography>
                 
                </Box>
                
                <CardContent sx={{ pt: 0, px: 3, pb: 3, flexGrow: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      lineHeight: 1.6,
                      textAlign: 'center',
                    }}
                  >
                    {item.about}
                  </Typography>
                </CardContent>
              </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WithSimpleCards;