import { useEffect, useRef, useState } from "react";
import {
	Box,
	Container,
	Grid,
	Typography,
	Card,
	Avatar,
	Grow,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

const KeyFeatureCards = ({ data, title = "Key Features", subtitle }) => {
	const [isVisible, setVisible] = useState(false);
	const ref = useRef(null);
	const theme = useTheme();

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.3 }
		);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => observer.disconnect();
	}, []);

	return (
		<Box sx={{ py: 8, backgroundColor: theme.palette.mode === "dark" ? "rgba(20, 20, 20, 0.8)" : "#f8f9fa" }}>
			<Container maxWidth="lg">
				<Box sx={{ mb: 6, textAlign: "center" }}>
					<Typography
						variant="h4"
						component="h2"
						sx={{
							fontWeight: 700,
							mb: 2,
							color: theme.palette.text.primary,
						}}
					>
						{title}
					</Typography>
					{subtitle && (
						<Typography
							variant="body1"
							sx={{
								color: theme.palette.text.secondary,
								maxWidth: 700,
								mx: "auto",
								lineHeight: 1.6,
							}}
						>
							{subtitle}
						</Typography>
					)}
				</Box>

				<Grid 
					container 
					spacing={4} 
					ref={ref}
				>
					{data.map((item, i) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							key={i}
						>
							<Grow
								in={isVisible}
								style={{
									transformOrigin: "center bottom",
									transitionDelay: `${i * 100}ms`,
								}}
								timeout={600}
							>
								<Card
									elevation={0}
									sx={{
										height: "100%",
										display: "flex",
										flexDirection: "column",
										borderRadius: 2,
										overflow: "hidden",
										transition: "transform 0.3s ease, box-shadow 0.3s ease",
										boxShadow: theme.palette.mode === "dark" 
											? "0 4px 20px rgba(0, 0, 0, 0.2)"
											: "0 4px 20px rgba(0, 0, 0, 0.05)",
										"&:hover": {
											transform: "translateY(-8px)",
											boxShadow: theme.palette.mode === "dark"
												? "0 10px 25px rgba(0, 0, 0, 0.3)"
												: "0 10px 25px rgba(0, 0, 0, 0.1)",
										},
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											p: 3,
											flexGrow: 1,
										}}
									>
										{item.icon && (
											<Avatar
												sx={{
													width: 56,
													height: 56,
													mb: 2.5,
													bgcolor: alpha(item.color, 0.12),
													color: item.color,
													boxShadow: `0 4px 12px ${alpha(item.color, 0.2)}`,
												}}
											>
												{item.icon}
											</Avatar>
										)}
										<Typography
											variant="h6"
											sx={{
												fontWeight: 700,
												mb: 1.5,
												color: theme.palette.text.primary,
											}}
										>
											{item.title}
										</Typography>
										<Typography
											variant="body2"
											sx={{
												color: theme.palette.text.secondary,
												lineHeight: 1.6,
												mb: 2,
												flexGrow: 1,
											}}
										>
											{item.subtitle}
										</Typography>
									</Box>
								</Card>
							</Grow>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
};
export default KeyFeatureCards;
