import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "../Container";
import { Form, Cover } from "./components";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const FullScreenHeroWithSubscriptionForm = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        width: 1,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container paddingY={{ xs: 4, sm: 6, md: 8 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 2,
                }}
              >
                AI Solutions Tailored for Your Business
              </Typography>
              
              <Typography
                variant="h6"
                component="p"
                sx={{ 
                  color: theme.palette.text.secondary,
                  mb: 4, 
                  fontSize: { xs: '1rem', md: '1.15rem' },
                  lineHeight: 1.6,
                  maxWidth: '90%'
                }}
              >
                We design, develop, and deploy cutting-edge AI solutions that transform your operations, 
                optimize workflows, and drive business growth.
              </Typography>
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 4 }}>
                <Button 
                  component={Link}
                  to="/contact"
                  variant="contained" 
                  color="primary"
                  size="large"
                  sx={{ 
                    borderRadius: 2,
                    px: 4,
                    py: 1.5
                  }}
                >
                  Get Started
                </Button>
                <Button 
                  component={Link}
                  to="/projects"
                  variant="outlined" 
                  color="primary"
                  size="large"
                  sx={{ 
                    borderRadius: 2,
                    px: 4,
                    py: 1.5
                  }}
                >
                  View Projects
                </Button>
              </Box>
              
              <Form />
            </Box>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                height: { xs: 300, md: 500 },
                width: '100%',
                position: 'relative',
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <Cover />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FullScreenHeroWithSubscriptionForm;